
















































































import { Component, Vue, Ref } from "vue-property-decorator";
import api from "@/api";
import { SupplierDto, UserDto, UserType } from "@/api/appService";
import PagedTableView from "@/components/PagedTableView.vue";

import ResetPassword from "@/views/ou/user/resetPassword.vue";

@Component({
  components: {
    PagedTableView,
    ResetPassword,
  },
})
export default class UserWorkDeveloper extends Vue {
  editUserFoundationId = 0;
  queryForm = {
    keyword: "",
    // userType: UserType.PlatformUser
  };
  userId = 0;

  fetchData(params: any) {
    return api.user.getAll(params);
  }

  handleCreate() {
    this.$router.push({
      name: "usersCreate",
    });
  }

  handleEdit(index: number, row: any) {
    this.$router.push({
      name: "usersEdit",
      params: { id: row.id!.toString() },
    });
  }

  jumpDetail(index: number, row: any) {
    this.$router.push({
      name: "usersDetail",
      params: { id: row.id!.toString() },
    });
  }

  handleResetPassword(index: number, row: any) {
    this.userId = row.id!;
    (this.$refs.resetPasswordForm as any).userId = this.userId;
    (this.$refs.resetPasswordForm as any).show = true;
  }

  handleDelete(index: number, row: any) {
    this.$confirm("你确定删除吗？", "提示").then(() => {
      api.user.delete({ id: row.id }).then(() => {
        this.$message.success("删除成功!");
        this.fetchData(this.queryForm);
      });
    });
  }

  handleEnableOrDisable(row: UserDto) {
    return row && row.isActive
      ? api.user.deActivate({ body: { id: row.id } })
      : api.user.activate({ body: { id: row.id } });
  }

  handleOnSaved() {
    this.fetchData(this.queryForm);
  }
}
