

















































































import {Component, Ref, Vue} from "vue-property-decorator";
import api from "@/api";
import {CreateUserDto, RoleListDto, UserDto, UserType,} from "@/api/appService";
import {ElForm} from "element-ui/types/form";
import AbSelect from "@/components/AbSelect.vue";

@Component({
  components: {
    AbSelect,
  },
})
export default class CreateExternalProject extends Vue {
  @Ref() readonly dataForm!: ElForm;
  form: UserDto | CreateUserDto = {
    userName: undefined,
    roleNames: [],
    surname: undefined,
    name: undefined,
    emailAddress: undefined,
    // userType: UserType.PlatformUser,
    isActive: true,
    id: 0,
    // departmentId: undefined,
    password: "",
    // organizationUnitId: undefined,
  };

  userId?: number;
  roleList: RoleListDto[] = [];

  submitting = false;

  created() {
    if (this.$route.params.id) {
      this.userId = Number(this.$route.params.id);
      api.user.get({id: this.userId}).then((res) => {
        this.form = {...res};
      });
    }

    this.fetchAllRoles();
  }

  fetchAllRoles() {
    api.role.getRoles({}).then((res) => {
      // if(this.isFoundationMode){
      //   this.roleList = res.items!.filter(role=>(role.roleScopes?.some(scope=>scope === PermissionDefinitionScope.Foundation)??false))??[];
      // } else if(this.isOrganizationUnitMode){
      //   this.roleList = res.items!.filter(role=>(role.roleScopes?.some(scope=>scope === PermissionDefinitionScope.Partner)??false))??[];
      // }

      this.roleList = res.items ?? [];

      this.roleList.map((item: any) => {
        item.name = item.name.toUpperCase();
      });
    });
  }

  async save() {
    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        this.submitting = true;
        this.form.surname = this.form.name;
        // this.form.userType = UserType.PlatformUser;
        // this.form.isActive = true;
        if ((this.form as any).id) {
          await api.user
            .update({
              body: this.form,
            })
            .catch((res) => {
              this.submitting = false;
            });
        } else {
          await api.user
            .create({
              body: this.form,
            })
            .catch((res) => {
              this.submitting = false;
            });
        }
        if (this.submitting) {
          this.$router.back();
          this.$message.success("更新成功");
        }
      }
    });
  }

  get isNewUser() {
    return !!(this.form as any).id === false;
  }

  get isFoundationMode(): boolean {
    return this.$route.query.mode === "foundation";
  }

  get isOrganizationUnitMode(): boolean {
    return this.$route.query.mode === "organizationUnit";
  }

  cancel() {
    this.$router.back();
    // (this.$refs.dataForm as any).resetFields();
    // this.$emit("input", false);
  }

  roleRule = {
    userName: [
      {
        required: true,
        message: "用户名必填",
        trigger: "blur",
      },
    ],
    phoneNumber: [
      {
        required: true,
        message: "手机号码必填",
        trigger: "blur",
      },
    ],
    name: [
      {
        required: true,
        message: "姓名必填",
        trigger: "blur",
      },
    ],
    emailAddress: [
      {
        required: true,
        message: "邮箱地址必填",
        trigger: "blur",
      },
      {
        message: "请填写正确的邮箱地址",
        type: "email",
        trigger: "blur",
      },
    ],
    password: [
      {
        required: true,
        message: "密码必填",
        trigger: "blur",
      },
    ],
    confirmPassword: [
      {
        required: true,
        message: "确认密码必填",
        trigger: "blur",
      },
    ],
  };
}
